import { createRouter, createWebHistory } from 'vue-router'
import Project from '@/views/ProjectsPage.vue'
import About from '@/views/AboutPage.vue'
import Contact from '@/views/ContactPage.vue'
import Portfolio from '@/views/PortfolioPage.vue'
import Enedis from '@/views/EnedisPage.vue'
import Vinci from '@/views/VinciPage.vue'
import Leadix from '@/views/LeadixPage.vue'
import { inject } from '@vercel/analytics';

const routes = [
    {
        path: '/',
        name: 'Projects',
        component: Project,
    },
    {
        path: '/about',
        name: 'About me',
        component: About,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/enedis-internship',
        name: 'Enedis',
        component: Enedis,
    },
    {
        path: '/vinci-internship',
        name: 'Vinci',
        component: Vinci,
    },
    {
        path: '/portfolio-project',
        name: 'Portfolio',
        component: Portfolio,
    },
    {
        path: '/leadix-internship',
        name: 'Leadix',
        component: Leadix,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
})

inject();

export default router