export default {
  data() {
    return {
      imagesLoaded: false,
    }
  },
  methods: {
    loadImages(imageSources) {
      let loadedCount = 0
      this.imagesLoaded = false

      imageSources.forEach((src) => {
        const img = new Image()
        img.onload = () => {
          loadedCount++
          if (loadedCount === imageSources.length) {
            setTimeout(() => {
              this.imagesLoaded = true
            }, 1000)
          }
        }
        img.src = src
      })
    },
  },
}
  