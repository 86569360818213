<template>
  <div class="NavigationBar">
      <div class="NavigationBar-left">
          <a href="/">VB</a>
      </div>
      <div class="NavigationBar-right">
          <div class="NavigationBar-right-theme-change">
              <i id="IconTheme" @click="toggleTheme(!isDarkMode)"></i>
          </div>
          <div class="NavigationBar-right-menu" @click="openMenu()">
              <div class="NavigationBar-right-menu-text">MENU</div>
              <div class="NavigationBar-right-menu-tool">
                  <div class="NavigationBar-right-menu-tool-component"></div>
                  <div class="NavigationBar-right-menu-tool-component"></div>
                  <div class="NavigationBar-right-menu-tool-component"></div>
              </div>
          </div>
      </div>
  </div>
  <div class="Menu">
    <div class="Menu-top">
      <div class="Menu-top-left">
        <a href="/">VB</a>
      </div>
      <div class="Menu-top-right link" @click="closeMenu()">
        <div class="Menu-top-right-text">{{ translate("CloseMenu") }}</div>
        <i class="uil uil-multiply"></i>
      </div>
    </div>
    <div class="Menu-middle">
      <div class="Menu-middle-title">MENU</div>
      <div class="Menu-middle-links">
        <nav>
          <router-link @click="closeMenu()" to="/" @click.prevent="scrollToTop">{{ translate("ProjectsTitle") }}</router-link>
          <router-link @click="closeMenu()" to="/about" @click.prevent="scrollToTop" >{{ translate("AboutTitle") }}</router-link>
          <router-link @click="closeMenu()" to="/contact" @click.prevent="scrollToTop">{{ translate("ContactTitle") }}</router-link>
        </nav>
      </div>
    </div>
    <div class="Menu-bottom">
      <div class="Menu-bottom-lang-button-box">
        <div class="Menu-bottom-lang-button">
          <div id="Button"></div>
          <button name ="lang" value="fr" type="button" class="Toggle-button" @click="toggleLanguage(!english)">{{ translate("FrenchButton") }}</button>
          <button name ="lang" value="en" type="button" class="Toggle-button" @click="toggleLanguage(!english)">{{ translate("EnglishButton") }}</button>
        </div>
      </div>
    </div>
  </div>
  <router-view :english="english"/>
</template>

<script>
import disableScroll from 'disable-scroll'
import en from './components/en'
import fr from './components/fr'

export default {
  data() {
    return {
      isDarkMode: false,
      english: false,
    }
  },

  mixins:[en, fr],

  methods: {
    toggleTheme(theme) {
      this.isDarkMode = theme;
      if (theme) {
        document.documentElement.classList.add('true');
        document.documentElement.classList.remove('false');
      } else {
        document.documentElement.classList.add('false');
        document.documentElement.classList.remove('true');
      }
      localStorage.setItem('theme', theme);
      this.iconChange();
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    iconChange() {
      var iconClass;
      if(!this.isDarkMode) {
        iconClass = "uil uil-sun"
      } else {
        iconClass = "uil uil-moon"
      }
      document.getElementById('IconTheme').className = iconClass
    },
    
    openMenu() {
      document.querySelector(".Menu").classList.toggle('Menu-open')
      disableScroll.on()
    },

    closeMenu() {
      document.querySelector(".Menu").classList.toggle('Menu-open')
      disableScroll.off()
    },

    toggleLanguage(language) {
      this.english = language
      localStorage.setItem('language', language)
      this.languageButtonClick()
    },

    languageButtonClick() {
      var btn = document.getElementById('Button')
      if(!this.english) {
        btn.style.left = '0'
      } else {
        btn.style.left = 'var(--leftButton)'
      }
    },

    translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    }
  },

  mounted() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.toggleTheme(JSON.parse(localStorage.getItem('theme')))
    this.toggleLanguage(JSON.parse(localStorage.getItem('language')))
  }
}
</script>

<style lang="scss">
@import url('@/style/reset.css');

.NavigationBar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--theme-first-color);
  width: 100%;
  height: 10vh;
  min-height: 40px;
  z-index: 100;
  position: fixed;
  
  .NavigationBar-left{
    cursor: pointer;
    padding-left: 2%;

    a{
      display: flex;
      align-items: center;
      color: var(--theme-first-color);
      font-size: 40px;
    }
  }

  .NavigationBar-left a:hover{
    color:var(--theme-second-color);
  }

  .NavigationBar-right{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 2%;

    .NavigationBar-right-theme-change{
      margin-right: 30px;
      font-size: 30px;
    }

    .NavigationBar-right-menu{
      display: flex;
      align-items: center;

      .NavigationBar-right-menu-text{
        font-size: 15px;
        font-weight: bolder;
        padding-right: 10px;
      }

      .NavigationBar-right-menu-tool-component{
        background-color: var(--theme-first-color);
        height: 3px;
        width: 21px;
        margin-top: 5px;
        margin-bottom: 5px;
        transition: ease .2s;
      }
    }
  }

  .NavigationBar-right-menu:hover{
    color:var(--theme-second-color);

    .NavigationBar-right-menu-tool-component{
      background-color: var(--theme-second-color);
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .NavigationBar-right-theme-change:hover{
    color:var(--theme-second-color);
  }
}

.Menu{
  visibility: hidden;
  position: fixed;
  margin-top: 100vh;
  z-index: 200;
  width: 100%;
  height: 100vh;
  background-color: var(--theme-background-navbar-color);
  color: var(--theme-first-color);
  transition: all 0.8s;

  .Menu-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10vh;
    min-height: 40px;

    .Menu-top-left{
      cursor: pointer;
      padding-left: 2%;

      a{
        display: flex;
        align-items: center;
        color: var(--theme-first-color);
        font-size: 40px;
      }
    }

    .Menu-top-right{
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-right: 1.8%;

      .Menu-top-right-text{
        font-size: 15px;
        font-weight: bolder;
        padding-right: 10px;
      }

      i{
        font-size: 30px;
      }
    }
  }

  .Menu-top-left a:hover{
    color: var(--theme-second-color);
  }

  .Menu-top-right:hover{
    color: var(--theme-second-color);
  }

  .Menu-middle{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    min-height: 300px;

    .Menu-middle-title{
      font-size: 20px;
      font-weight: bolder;
      margin-top: 13vh;
      margin-bottom: 5vh;
    }

    .Menu-middle-links{
      display: flex;
      flex-direction: column;
      
      a{
        color: var(--theme-first-color);
        display: flex;
        justify-content: center;
        font-size: 60px;
        margin-bottom: 5vh;
        font-weight: bolder;
        text-align: center;
      }
    }

    nav a:hover,
    nav a.router-link-exact-active{
      color: var(--theme-second-color);
    }
  }

  .Menu-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20vh;
    
    .Menu-bottom-lang-button-box{
      width: 350px;
      height: 50px;
      position: relative;
      border-radius: 30px;
      background: var(--theme-background-color);
      display: flex;
      justify-content: center;
      align-items: center;

      #Button{
        --leftButton: 175px;
        left: 0;
        top: 0;
        position: absolute;
        width: 175px;
        height: 100%;
        background: var(--theme-second-color);
        border-radius: 30px;
        transition: .5s;
      }

      .Toggle-button{
        width: 175px;
        height: 50px;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
        position: relative;
        color: var(--theme-first-color);
        font-size: 18px;
      }
    }
  }
}

.Menu-open{
  visibility: visible;
  margin-top: 0;
  transition: margin-top 0.7s;
}

/*========================== RESPONSIVE ==========================*/
@media screen and (min-width: 2500px){
  .NavigationBar{

    .NavigationBar-left{

      a{
        font-size: 50px;
      }
    }

    .NavigationBar-right{

      .NavigationBar-right-theme-change{
        margin-right: 40px;
        font-size: 40px;
      }

      .NavigationBar-right-menu{

        .NavigationBar-right-menu-text{
          font-size: 30px;
        }

        .NavigationBar-right-menu-tool-component{
          height: 4px;
          width: 30px;
        }
      }
    }
  }

  .Menu{

    .Menu-top{
      height: 10vh;

      .Menu-top-left{

        a{
          font-size: 50px;
        }
      }

      .Menu-top-right{

        .Menu-top-right-text{
          font-size: 30px;
        }

        i{
          font-size: 50px;
        }
      }
    }

    .Menu-middle{

      .Menu-middle-title{
        font-size: 25px;
      }

      .Menu-middle-links{

        a{
          font-size: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) and (max-height: 1200px){
  .NavigationBar{
    height: 8vh;

    .NavigationBar-left{

      a{
          font-size: 30px;
      }
    }

    .NavigationBar-right{

      .NavigationBar-right-theme-change{
        margin-right: 25px;
        font-size: 25px;
      }

      .NavigationBar-right-menu{

        .NavigationBar-right-menu-text{
          font-size: 13px;
        }

        .NavigationBar-right-menu-tool-component{
          height: 2px;
          width: 19px;
        }
      }
    }
  }

  .Menu{

    .Menu-top{
        height: 8vh;

      .Menu-top-left{

        a{
          font-size: 30px;
        }
      }

      .Menu-top-right{

        .Menu-top-right-text{
          font-size: 13px;
        }

        i{
          font-size: 30px;
        }
      }
   }

    .Menu-middle{

      .Menu-middle-title{
        font-size: 15px;
      }

      .Menu-middle-links{

        a{
          font-size: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) and (max-height: 850px), (max-height: 700px){
  .NavigationBar{
      
    .NavigationBar-left{

      a{
        font-size: 20px;
      }
    }

    .NavigationBar-right{

      .NavigationBar-right-theme-change{
        margin-right: 18px;
        font-size: 18px;
      }

      .NavigationBar-right-menu{

        .NavigationBar-right-menu-text{
          font-size: 10px;
        }

        .NavigationBar-right-menu-tool-component{
          height: 1px;
          width: 14px;
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }

      .NavigationBar-right-menu:hover{

        .NavigationBar-right-menu-tool-component{
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .Menu{

    .Menu-top{

      .Menu-top-left{

        a{
          font-size: 20px;
        }
      }

      .Menu-top-right{

        .Menu-top-right-text{
          font-size: 10px;
        }

        i{
          font-size: 20px;
        }
      }
    }

    .Menu-middle{

      .Menu-middle-title{
        font-size: 12px;
      }

      .Menu-middle-links{

        a{
          font-size: 30px;
        }
      }
    }

    .Menu-bottom{
      
      .Menu-bottom-lang-button-box{
        width: 260px;
        height: 40px;

        #Button{
          --leftButton: 130px;
          width: 130px;
        }

        .Toggle-button{
          width: 130px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px){
  .NavigationBar{
    background-color: var(--theme-background-navbar-color);
  }
}

@media screen and (max-height: 430px){

  .Menu{

    .Menu-middle{
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 60vh;
      min-height: 150px;

      .Menu-middle-title{
        margin-top: 1vh;
        margin-bottom: 3vh;
      }

      .Menu-middle-links{
        display: flex;
        flex-direction: column;
        
        a{
          margin-bottom: 8vh;
          font-size: 25px;
        }
      }
    }
  }
}
</style>