<template>
    <LoadingPage v-if="!imagesLoaded" :text="translate('LoadingText')"></LoadingPage>
    <div v-else>
        <div class="Internship-enedis">
            <div class="Internship-top">
                <div class="Internship-top-date" v-motion-slide-top>{{ translate("DateEnedis") }}</div>
                <div class="Internship-top-job" v-motion-slide-top>
                    {{ translate("TextEnedis") }} &mdash; <a href="https://www.enedis.fr">ENEDIS</a>
                </div>
                <div class="Internship-top-title" v-motion-slide-top>{{ translate("EnedisTitle") }}</div>
                
                <div class="Internship-top-text" v-motion-slide-top>{{ translate("EnedisText") }}</div>
                <div class="Internship-top-task-title" v-motion-slide-top> {{ translate("TaskDone") }}</div>
                <div class="Internship-top-task-done" v-motion-slide-top>
                    <ul>
                        <li>{{ translate("EnedisTask1") }}</li>
                        <li>{{ translate("EnedisTask2") }}</li>
                    </ul>
                </div>
                <div class="Internship-top-tool" v-motion-slide-top>Microsoft Power BI &mdash; DAX &mdash; Microsoft Excel</div>
            </div>
            <div class="Internship-bottom" v-motion-slide-top>
                <img loading="eager" src="@/assets/pictures/enedis1.png" alt="Example of dashboard I created">
                <div class="Internship-bottom-blank"></div>
                <img loading="eager" src="@/assets/pictures/enedis2.png" alt="Example of dashboard I created">
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import en from '@/components/en'
import fr from '@/components/fr'
import Footer from '../components/Footer'
import LoadingPage from '@/components/LoadingPage.vue'
import ImageLoaderMixin from '@/mixins/ImageLoaderMixin.js'

export default {
  data() {
    return {
    }
  },

  components: {
    Footer,
    LoadingPage
  },

  props: {
    english: Boolean,
  },
    
  mixins:[en, fr, ImageLoaderMixin],

    mounted() {
        const images = [
            require("@/assets/pictures/enedis1.png"),
            require("@/assets/pictures/enedis2.png"),
        ]
        this.loadImages(images)
    },

    methods: {

        translate(prop) {
            if(!this.english) {
                return this["fr"][prop]
            } else {
                return this["en"][prop]
            }
        }
    },
}
</script>

<style lang="scss">
@import url('@/style/reset.css');

.Internship-enedis{
    width: 100%;
    height: fit-content;
    min-width: 300px;
    color: var(--theme-first-color);
    background-color: var(--theme-background-color);
    transition: background-color 0.8s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Internship-top{
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
        height: fit-content;

        .Internship-top-date{
            font-size: 14px;
            font-style: italic;
            padding-top: 20vh;       
        }

        .Internship-top-job{
            padding-top: 2vh;
            font-size: 20px;

            a{
                transition: color 0.2s ease;
                color: var(--theme-first-color);
                display: inline-block;
                animation-name: wiggle;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }

            a:hover{
                color: var(--theme-second-color);
                animation: none;
            }

            @keyframes wiggle {
                0% { transform: rotate(0deg); }
                80% { transform: rotate(0deg); }
                85% { transform: rotate(3deg); }
                95% { transform: rotate(-3deg); }
                100% { transform: rotate(0deg); }
            }
        }

        .Internship-top-title{
            height: fit-content;
            font-weight: bold;
            padding-top: 5vh;
            font-size: 40px;
        }

        .Internship-top-text{
            font-size: 17px;
            width: 100%;
            padding-top: 8vh;
            white-space: pre-line;
            line-height: 1.5;
            text-align: justify;
        }

        .Internship-top-task-title{
            padding-top: 8vh;
            font-weight: bold;
        }

        .Internship-top-task-done{
            width: 90%;
            padding-top: 1vh;
            padding-left: 5%;
            line-height: 1.5;
            list-style-type: disc;
            font-size: 17px;
            text-align: justify;

            ul {
                list-style-type: disc;
            }

            li {
                display: list-item;
                padding-top: 1vh;
            }
        }

        .Internship-top-tool{
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            font-weight: bold;
            font-size: 20px;
            padding-top: 5vh;
            color: var(--theme-second-color);
        }
    }

    .Internship-bottom{
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 10vh;
        padding-bottom: 10vh;
        height: min-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            min-width: 300px;
            max-width: 800px;
            min-height: 150px;
            max-height: 400px;
            transition: all 0.2s ease-in-out;
        }

        img:hover{
            transform: scale(1.1);
        }
        
        .Internship-bottom-blank{
            width: 100%;
            height: 50px;
        }
    }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-width: 800px){
    
    .Internship-enedis{

        .Internship-bottom{

            img{
                width: 500px;
                height: 250px;
            }
            
            .Internship-bottom-blank{
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 540px){
    
    .Internship-enedis{

        .Internship-top{

            .Internship-top-date{
                font-size: 10px;  
            }

            .Internship-top-job{
                font-size: 17px;
            }

            .Internship-top-title{
                font-size: 25px;
            }

            .Internship-top-text{
                font-size: 15px;
            }

            .Internship-top-task-done{
                font-size: 15px;
            }

            .Internship-top-tool{
                font-size: 17px;
            }
        }

        .Internship-bottom{

            img{
                width: 250px;
                height: 125px;
            }

            img:hover{
                transform: scale(1);
            }
            
            .Internship-bottom-blank{
                height: 50px;
            }
        }
    }
}
</style>