export default {
    data() {
        return {
            en: {
                // MENU
                ProjectsTitle: "Projects",
                AboutTitle: "About me",
                ContactTitle: "Contact",
                FrenchButton: "French",
                EnglishButton: "English",
                CloseMenu: "CLOSE",
                LoadingText: "Loading...",

                // HOME PAGE
                TextHomePage: "Student in computer science. I am truly passionate about web development and data science !",

                // PROJECT PAGE
                MyInternshipText: "My experiences",
                ContactText: "Feel free to contact me",

                // ABOUT PAGE
                HelloTitle: "Hello !\nI'm Victor",
                AboutTopText: "I am 22 years old and I come from Bordeaux, France.",
                ScrollText: "Scroll down to learn more",
                TitleTool: "Tools used",
                TitleWeb: "Web development skills",
                TitleData: "Data science skills",
                TextPython: "Python (data, web scraping, AI)",
                TitleLearning: "Skills I am learning",
                FormationTitle: "Education",
                FormationText1: "I'm currently in my fourth year of a master's degree in computer science at CESI in Bordeaux, France.",
                FormationText2: "PBL Method (Problem Based Learning), learning through concrete group projects.",
                FormationText3: "Project management",
                FormationText4: "Web/software development project, Big data, network, system administration",
                HobbiesTitle: "Hobbies",
                Trip: "Travels",
                TripText: "From childhood to today, I've had the opportunity to travel, which has been a real blessing for me. I've had the opportunity to visit several countries such as the United States, the Netherlands, Ireland, Greece and many others.\n\nWhat I particularly enjoy is exploring the countries I visit and discovering their culture. \n\nIn the future, I'd love to travel to Asia, as I've never had the opportunity to do so.",
                Movie: "Cinema",
                MovieText: "Cinema is more than just entertainment for me, it's a passion that feeds my mind and soul.\n\nEvery film I watch is an opportunity to grow, learn and feel. It inspires me, motivates me and broadens my horizons.\n\nI love science fiction films, especially the Marvel and Star Wars saga.",
                Sport: "Bodybuilding",
                SportText: "I've been doing bodybuilding for 5 years now.\n\nWeight training has helped me improve my self-confidence. By developing my physical strength, I have developed a mental strength that translates into all aspects of my life.\n\nI feel more self-confident, able to take on any challenge that comes my way.",

                // CONTACT PAGE
                TitlePage: "Let's get in touch !",
                ResumeText: "You can download my resume",
                ResumeDownloadFR: "Resume in french",
                ResumeDownloadEN: "Resume in english",

                // INTERNSHIP PAGE
                TaskDone: "Tasks done",

                // ENEDIS PAGE
                DateEnedis: "April 2022 - July 2022",
                LocationEnedis: "Mérignac, France",
                TextEnedis: "Data Analyst internship",
                EnedisTitle: "Development of dashboards",
                EnedisText: "This internship was my first professional experience in the field of data and gave me the desire to continue in it.",
                EnedisTask1: "Development of dashboards under POWER BI for the Regional Executive Committee.",
                EnedisTask2: "Develop Power BI training documents.",

                // VINCI PAGE
                DateVinci: "January 2023 - April 2023",
                LocationVinci: "Mérignac, France",
                TextVinci: "Data Scientist internship",
                VinciTitle: "Smartvia - REGINA : The connected road for the maintenance of road infrastructures",
                VinciText: "Between 2015 and 2019, a highway bypass was built in the city of Regina, in the capital of Saskatchewan, Canada. The VINCI Group participated in this project and placed sensors in the pavement to study the deformation of the road. The objective of this internship is to automate the processing of the collected data and to design a dashboard to visualize them.",
                VinciTask1: "Creation of python scripts allowing the automatic processing of pavement structure deformation data.",
                VinciTask2: "Creation of a SQL database for the processed data.",
                VinciTask3: "Elaboration of a dashboard allowing the consultation of the data.",
                VinciTask4: "Initialization to the implementation of a prediction model using artificial intelligence techniques.",

                // LEADIX PAGE
                DateLeadix: "September 2023 - January 2024",
                LocationLeadix: "Brussels, Belgium",
                TextLeadix: "Python Backend Developer Internship",
                LeadixTitle: "Development and maintenance of a Saas",
                LeadixText: "I joined Leadix as a Python backend developer to work on a tool. During the internship, I implemented and improved functionalities and also did maintenance. The aim of these missions was to have a tool that would assist a growth marketer by lightening his workload as much as possible, particularly on long tasks that could be automated. This would enable a growth marketer to devote more time to other tasks.\n\nI discovered Belgium for the first time, and Brussels in particular. I discovered a cosmopolitan city and an exciting country. Finally, I met the Leadix team. People from whom I learned a lot and with whom I had a great time.",
                LeadixTask1: "Maintenance of a SaaS hosted on Microsoft Azure.",
                LeadixTask2: "CI/CD management.",
                LeadixTask3: "Management of the SaaS front end on Bubble (no-code).",
                LeadixTask4: "Implementation of new functions using web scraping and various APIs.",

                // PORTFOLIO PROJECT PAGE
                MyPersonalText: "My personal projects",
                PortfolioTitleBox: "Personal website",
                PortfolioText: "Having the desire to learn web development, I decided to realize a personal portfolio on which you are currently.\nI chose the Vue.js framework because it is a very popular framework nowadays but also because it is a solution which presents many advantages of use.\n\nThis portfolio is not yet a final version. I'm constantly trying to learn new notions in order to improve it and get better at it so stay tuned !",
            }
        }
    }
}