<template>
  <div class="Loader">
      <span class="Loader-animation"></span>
      <h1>{{ text }}</h1>
  </div>
</template>
  
<script>
import en from './en'
import fr from './fr'

export default {
  name: 'LoadingPage',

  mixins:[en, fr],

  props: {
    english: Boolean,
    text: String
  },

  methods: {
    translate(prop) {
      if(!this.english) {
        return this["fr"][prop]
      } else {
        return this["en"][prop]
      }
    }
  }
}
</script>

<style lang="scss">
@import url('@/style/reset.css');

.Loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: var(--theme-first-color);
  background-color: var(--theme-background-color);
  transition: background-color 0.8s;

  .Loader-animation {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: inline-block;
    border-top: 7px solid var(--theme-first-color);
    border-right: 7px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .Loader-animation::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border-bottom: 7px solid var(--theme-second-color);
    border-left: 7px solid transparent;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  h1 {
    margin-top: 5vh;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 650px), (max-height: 700px) {
  .Loader {
    .Loader-animation {
      width: 80px;
      height: 80px;
      border-top: 5px solid var(--theme-first-color);
      border-right: 5px solid transparent;
    }
    .Loader-animation::after {
      width: 80px;
      height: 80px;
      border-bottom: 5px solid var(--theme-second-color);
      border-left: 5px solid transparent;
    }

    h1 {
      font-size: 1.15rem;
    }
  }
}
</style>