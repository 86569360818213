<template>
  <LoadingPage v-if="!imagesLoaded" :text="translate('LoadingText')"></LoadingPage>
  <div v-else>
    <div class="PersonalProject">
      <div class="PersonalProject-background"></div>
      <div class="PersonalProject-content">
        <div class="PersonalProject-content-date" v-motion-slide-top>2023</div>
        <div class="PersonalProject-content-title" v-motion-slide-top>{{ translate("PortfolioTitleBox") }}</div>
        <div class="PersonalProject-content-text" v-motion-slide-top>
          {{ translate("PortfolioText") }}
          <span class="emoji">&#x1F609;</span>
        </div>
        <div class="Internship-content-tool" v-motion-slide-top>Vue.js &mdash; SCSS &mdash; HTML &mdash; JavaScript</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import en from '@/components/en'
import fr from '@/components/fr'
import twemoji from 'twemoji';
import Footer from '../components/Footer'
import LoadingPage from '@/components/LoadingPage.vue'
import ImageLoaderMixin from '@/mixins/ImageLoaderMixin.js'

export default {
  
data() {
  return {
  }
},

components: {
  Footer,
  LoadingPage
},

props: {
  english: Boolean,
},
  
mixins:[en, fr, ImageLoaderMixin],

mounted() {
  twemoji.parse(this.$el)
  const images = [
    require("@/assets/pictures/portfolio1.jpg"),
  ]
  this.loadImages(images)
},

  methods: {
      translate(prop) {
          if(!this.english) {
              return this["fr"][prop]
          } else {
              return this["en"][prop]
          }
      }
  },
}
</script>

<style lang="scss">
@import url('@/style/reset.css');

.PersonalProject{
  width: 100%;
  height: 100vh;
  min-width: 300px;
  min-height: 500px;
  color: var(--theme-first-color);
  background-color: var(--theme-background-color);
  transition: background-color 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;

  .PersonalProject-background{
    width: 80%;
    height: 80%;
    min-height: 400px;
    background-image: url(@/assets/pictures/portfolio1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    opacity: 0.4;
  }

  .PersonalProject-content{
    position: absolute;
    width: 70%;
    height: 70%;
    min-height: 300px;

    .PersonalProject-content-date{
      font-size: 14px;
      font-style: italic;
    }

    .PersonalProject-content-title{
      font-weight: bold;
      padding-top: 10vh;
      font-size: 35px;
    }

    .PersonalProject-content-text{
      font-size: 20px;
      padding-top: 5vh;
      white-space: pre-line;
      line-height: 1.5;

      .emoji{
        width: 20px;
        height: 20px;
      }
    }

    .Internship-content-tool{
      display: flex;
      justify-content: center;
      width: 100%;
      font-weight: bold;
      font-size: 20px;
      padding-top: 10vh;
    }
  }
}

/*========================== RESPONSIVE ==========================*/
@media screen and (max-height: 800px){
  .PersonalProject{

    .PersonalProject-content{

      .PersonalProject-content-title{
        padding-top: 4vh;
        font-size: 30px;
      }

      .PersonalProject-content-text{
        font-size: 15px;
        padding-top: 4vh;

        .emoji{
          width: 15px;
          height: 15px;
        }
      }

      .Internship-content-tool{
        font-size: 15px;
        padding-top: 7vh;
      }
    }
  }
}

@media screen and (max-width: 800px){
  .PersonalProject{

    .PersonalProject-content{

      .PersonalProject-content-date{
        font-size: 12px;
      }

      .PersonalProject-content-title{
        padding-top: 2vh;
        font-size: 22px;
      }

      .PersonalProject-content-text{
        font-size: 12px;
        padding-top: 2vh;

        .emoji{
          width: 12px;
          height: 12px;
        }
      }

      .Internship-content-tool{
        font-size: 10px;
        padding-top: 3vh;
      }
    }
  }
}

@media screen and (max-width: 370px){
  .PersonalProject{

    .PersonalProject-content{

      .PersonalProject-content-date{
        font-size: 10px;
      }

      .PersonalProject-content-title{
        padding-top: 2vh;
        font-size: 20px;
      }

      .PersonalProject-content-text{
        font-size: 10px;
        padding-top: 2vh;

        .emoji{
          width: 10px;
          height: 10px;
        }
      }

      .Internship-content-tool{
        font-size: 10px;
        padding-top: 3vh;
        text-align: center;
      }
    }
  }
}


</style>